import React from "react";
import Container from "react-bootstrap/Container";

const Footer = (props) => {
  const bgStyle = { backgroundColor: "#f5f5f5" };

  return (
    <footer style={bgStyle} className="mt-auto py-5 text-center " id="contact">
      <Container>
        {props.children}
        <p>
          <small className="text-muted">
            <i className="fas fa-code" /> with <i className="fas fa-heart" /> by{" "}
            <a
              rel="noopener"
              href="https://github.com/hashirshoaeb"
              aria-label="My GitHub"
            >
              <span >Hashir Shoaib</span>
            </a>
            {" "}
            using
            {" "}
            <i className="fab fa-react" />
            {" "}
            Project code is open source. Feel free to fork and make your own
            version.
          </small>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
